import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  packages_check,
} from "@/auth/utils";

Vue.use(VueRouter);
console.log('packages_check :>> ', packages_check);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/stores/store_set_add.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/dashboard',
    //   name: 'dashboard',
    //   component: () => import('@/views/reports/Users.vue'),
    //   meta: {
    //     pageTitle: 'Home',
    //     breadcrumb: [
    //       {
    //         text: 'Home',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: "/lineoa/show",
      name: "connectOA",
      component: () => import("@/views/lineoa/show_OA.vue"),
      meta: {
        pageTitle: "Add LINE OA",
        breadcrumb: [
          {
            text: "addLine",
            active: true,
          },
        ],
      },
    },
    {
      path: "/lineoa/add",
      name: "connectOA",
      component: () => import("@/views/lineoa/addLine.vue"),
      meta: {
        pageTitle: "เชื่อมต่อ LINE OA",
        
      },
    },

    // *===============================================---*
    // *--------- Campain ---- ---------------------------------------*
    // *===============================================---*

    {
      path: "/PushMessage",
      name: "PushMessage",
      component: () => import("@/views/PushMessage/list.vue"),
    },

    {
      path: "/PushMessage/add",
      name: "PushMessage",
      component: () => import("@/views/PushMessage/add.vue"),
    },
    {
      path: "/PushMessage/edit",
      name: "PushMessage",
      component: () => import("@/views/PushMessage/edit.vue"),
    },

    // *===============================================---*
    // *--------- BroadcastMessage ---- ---------------------------------------*
    // *===============================================---*

    {
      path: "/BroadcastMessage",
      name: "BroadcastMessage",
      component: () => import("@/views/BroadcastMessage/list.vue"),
    },

    {
      path: "/BroadcastMessage/add",
      name: "BroadcastMessage",
      component: () => import("@/views/BroadcastMessage/add.vue"),
    },
    {
      path: "/BroadcastMessage/edit",
      name: "BroadcastMessage",
      component: () => import("@/views/BroadcastMessage/edit.vue"),
    },

    {
      path: "/changepackage",
      name: "changepackage",
      component: () => import("@/views/ChangePackage/change_package.vue"),
    },

    // *===============================================---*
    // *--------- hotspot ---- ---------------------------------------*
    // *===============================================---*

    {
      path: "/hotspot",
      name: "device",
      component: () => import("@/views/stores/table_hotspot.vue"),
      meta: {
        breadcrumb: [
          {
            text: "Hotspot",
            active: true,
          },
        ],
      },
    },

    // *===============================================---*
    // *--------- store ---- ---------------------------------------*
    // *===============================================---*

    {
      path: "/index",
      name: "index",
      component: () => import("@/views/Raning/index.vue"),
      meta: {},
    },
    {
      path: "/store",
      name: "store",
      component: () => import("@/views/stores/store_set_add.vue"),
      meta: {},
    },

    {
      path: "/store/detail",
      name: "store-detail",
      component: () => import("@/views/stores/store_detail.vue"),
    },
    {
      path: "/store/add-brach",
      name: "store-detail",
      component: () => import("@/views/stores/add_brach.vue"),
    },
    {
      path: "/store/edit",
      name: "store-detail",
      component: () => import("@/views/stores/Edit_brach.vue"),
    },

    {
      path: "/store/add-user",
      name: "store-add-user",
      component: () => import("@/views/stores/add_user.vue"),
    },

    {
      path: "/MessageCard",
      name: "MessageCard",
      component: () => import("@/views/MessageCard/MessageCard.vue"),
      meta: {},
    },
    {
      path: "/create/MessageCard",
      name: "MessageCard",
      component: () => import("@/views/MessageCard/Create_MessgeCard.vue"),
      meta: {},
    },
   
    {
      path: "/edit/MessageCard",
      name: "MessageCard",
      component: () => import("@/views/MessageCard/Edit_MessageCard.vue"),
      meta: {},
    },
    {
      path: "/splash",
      name: "splash",
      component: () => import("@/views/SplashPage/index.vue"),
      meta: {
        pageTitle: "",
      },
    },
    {
      path: "/create/splash",
      name: "splash",
      component: () => import("@/views/SplashPage/create.vue"),
      meta: {},
    },
    {
      path: "/edit/splash",
      name: "splash",
      component: () => import("@/views/SplashPage/edit.vue"),
      meta: {},
    },

    // {
    //   path: "/BrandPages",
    //   name: "BrandPages",
    //   component: () => import("@/views/BrandPages/index.vue"),
    // },
    {
      path: "/BrandPages",
      name: "BrandPages",
      component: () => import("@/views/BrandPages/create.vue"),
    
    },
    {
      path: "/edit/BrandPages",
      name: "BrandPages",
      component: () => import("@/views/BrandPages/edit.vue"),
     
    },




    {
      path: "/users",
      name: "reports_users",
      component: () => import("@/views/reports/Users.vue"),
    },

    {
      path: "/impressions",
      name: "reports_impressions",
      component: () => import("@/views/reports/Impressions.vue"),
    },

    {
      path: "/connections",
      name: "reports_connections",
      component: () => import("@/views/reports/Connections.vue"),
    },

    {
      path: "/traffic",
      name: "reports_traffic",
      component: () => import("@/views/reports/Traffic.vue"),
    },

    //  {
    //   path: 'people',
    //   name: 'reports_people',
    //   component: () => import('@/views/reports/People.vue'),
    // },

    {
      path: "/register/users/from",
      name: "register_users",
      component: () => import("@/views/reports/register_users.vue"),
    },
    {
      path: "/register/users/line",
      name: "register_users_line",
      component: () => import("@/views/reports/register_users_line.vue"),
    },

    {
      path: "/profile/line",
      name: "register_users_line",
      component: () => import("@/views/profile/Profile.vue"),
    },
    {
      path: "/profile/from",
      name: "register_users",
      component: () => import("@/views/profile/Profile.vue"),
    },

    {
      path: "/profile/from",
      name: "register_users",
      component: () => import("@/views/profile/Profile.vue"),
    },

    {
      path: "/credit",
      name: "credit",
      component: () => import("@/views/package/credit.vue"),
    },

 
    {
      path: "/PaymentMethod",
      name: "payment_method",
      component: () => import("@/views/package/payment_method.vue"),
    },

    {
      path: "/Payment",
      name: "Package",
      component: () => import("@/views/package/payment.vue"),
    },
    {
      path: "/TaxInvoice",
      name: "tax_invoice",
      component: () => import("@/views/package/tax_invoice.vue"),
    },

    {
      path: "/TermsOfService",
      name: "terms_of_service",
      component: () => import("@/views/package/terms_of_service.vue"),
    },
    {
      path: "/Term-Of-Uuse",
      name: "term_of_use",
      component: () => import("@/views/package/term_of_use.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/Bandwidth&Session",
      name: "Bandwidth_Session",
      component: () => import("@/views/stores/Bandwidth_Session.vue"),
    },

    {
      path: "/TargetAudience",
      name: "TargetAudience",
      component: () => import("@/views/TargetAudience/Overview.vue"),
    },
    {
      path: "/taglist",
      name: "taglist",
      component: () => import("@/views/TargetAudience/TagInsert/taglist.vue"),
    },

    {
      path: "/ReportLine",
      name: "ReportLine",
      component: () => import("@/views/reports/people_analytics/line.vue"),
    },
    {
      path: "/ReportUserAll",
      name: "ReportUserAll",
      component: () => import("@/views/reports/people_analytics/user_all.vue"),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/reports/dashboard.vue"),
    },
    {
      path: "/contect",
      name: "contect",
      component: () => import("@/views/contect.vue"),
    },

    {
      path: "/sales",
      name: "sales",
      component: () => import("@/views/SocioManager/sales/index.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/sales/order",
      name: "sales_order",
      component: () => import("@/views/sales_order/products_order/index.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/sales/checkout",
      name: "sales_checkout",
      component: () => import("@/views/sales_order/products_order/checkout.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/purchase",
      name: "payment_history2",
      component: () => import("@/views/sales_order/purchase.vue"),
      meta: {
        // layout: "full",
      },
    },

    {
      path: "/PaymentHistory",
      name: "payment_history",
      component: () => import("@/views/sales_order/payment_history.vue"),
    },



    {
      path: "/sales/package",
      name: "sales-Package",
      component: () => import("@/views/sales_order/packages_order/index.vue"),
    },
    {
      path: "/sales/checkout/packages",
      name: "sales-Package",
      component: () => import("@/views/sales_order/packages_order/Checkout.vue"),
    },




    {
      path: "/sales/payment/options",
      name: "sales_payment_options",
      component: () => import("@/views/SocioManager/sales/payment_options.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/sales/payment/installment",
      name: "sales_payment_installment",
      component: () => import("@/views/SocioManager/sales/FormWizarInstallment.vue"),
      meta: {
        layout: "full",
      },
    },
    // ##############################################################
    {
      path: "/socio/manager/product",
      name: "socio_manager_product",
      component: () => import("@/views/SocioManager/products/index.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/socio/manager/packages",
      name: "socio_manager_packages",
      component: () => import("@/views/SocioManager/packages/index.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/liff/index",
      name: "liff_index",
      component: () => import("@/views/liff/salse.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
   
      },
    },
    {
      path: "/liff/sales/checkout",
      name: "liff_sales_checkout",
      component: () => import("@/views/liff/checkout.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
     
      },
    },
    {
      path: "/salse/order/sucess",
      name: "salse_order_sucess",
      component: () => import("@/views/liff/salse_order_sucess.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
     
      },
    },

    {
      path: "/liff/auth",
      name: "auth_liff",
      component: () => import("@/views/liff/auth_liff.vue"),
     
      meta: {
        resource: "Auth",
        layout: 'full',
      
      },
    },

    {
      path: "/liff/BrandPages",
      name: "liff-BrandPages",
      component: () => import("@/views/BrandPages/create.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
     
      },
    },
    {
      path: "/liff/SplashPage",
      name: "liff-SplashPage",
      component: () => import("@/views/SplashPage/index_liff.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        layout: 'campain',
     
      },
    },
    {
      path: "/liff/SplashPage/edit",
      name: "liff-SplashPage",
      component: () => import("@/views/SplashPage/edit_liff.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        layout: 'campain',
     
      },
    },
    {
      path: "/liff/SplashPage/create",
      name: "liff-SplashPage",
      component: () => import("@/views/SplashPage/create_liff.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        layout: 'campain',
     
      },
    },
    {
      path: "/liff/quickPosts",
      name: "liff-quickPosts",
      component: () => import("@/views/quickPosts/index_liff.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        layout: 'campain',
     
      },
    },
    {
      path: "/liff/quickPosts/edit",
      name: "liff-quickPosts",
      component: () => import("@/views/quickPosts/edit_liff.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        layout: 'campain',
     
      },
    },
    {
      path: "/liff/quickPosts/create",
      name: "liff-quickPosts",
      component: () => import("@/views/quickPosts/create_liff.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        layout: 'campain',
     
      },
    },
    // ---------------------------------------------------------------------------------
    {
      path: "/web/quickPosts",
      name: "web-quickPosts",
      component: () => import("@/views/quickPosts_web/index_liff.vue"),
     
    },
    {
      path: "/web/quickPosts/edit",
      name: "web-quickPosts",
      component: () => import("@/views/quickPosts_web/edit_liff.vue"),
      
    },
    {
      path: "/web/quickPosts/create",
      name: "web-quickPosts",
      component: () => import("@/views/quickPosts_web/create_liff.vue"),
      
    },

    {
      path: "/dev",
      name: "dev",
      component: () => import("@/views/dev.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/role",
      name: "role",
      component: () => import("@/views/users-list/UsersList.vue"),
      meta: {
        pageTitle: "Role",
        breadcrumb: [
          {
            text: "Role",
            active: true,
          },
        ],
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },

    {
      path: "/logout",
      name: "logout",
      component: () => import("@/views/logout.vue"),
      meta: {
        // layout: 'full',
        // resource: 'Auth',
        // redirectIfLoggedIn: true,
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/auth",
      name: "auth",
      component: () => import("@/views/Auth.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/pages/miscellaneous/not-authorized",
      name: "misc-not-authorized",
      component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },

    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "login" });

    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
